import React, { useState, useEffect, Suspense, Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "../src/Component/Screens/Utils/Layout";



function App() {

const Dashboard = React.lazy(() => import("./Component/Screens/DashBord"));
const Users = React.lazy(() => import("./Component/Screens/Users"));
const Stocks = React.lazy(() => import("./Component/Screens/Stocks"));
const Stocks1 = React.lazy(() => import("./Component/Screens/Stocks1.js"));
const StocksScreener = React.lazy(() =>
  import("./Component/Screens/StocksScreener")
);
const Admin = React.lazy(() => import("./Component/Screens/Admin"));
const FileUpload = React.lazy(() => import("./Component/Screens/Fileupload"));
const Login = React.lazy(() => import("./Component/Screens/login/Login"));
const Register = React.lazy(() => import("./Component/Screens/login/Register.js"));
const Industry = React.lazy(() => import("./Component/Screens/Industry.js"));
const Sector = React.lazy(() => import("./Component/Screens/Sector.js"));
const Category = React.lazy(() => import("./Component/Screens/Category.js"));
const Categorystock = React.lazy(() => import("./Component/Screens/Categorystock.js"));
const Tasis = React.lazy(() => import("./Component/Screens/tasis/Tasis.js"));
const Technical =  React.lazy(() => import("./Component/Screens/Technical/Technical.js"));
const Subscription =  React.lazy(() => import("./Component/Screens/Subscription/Subscription.js"));
const Preimumfeatures =  React.lazy(() => import("./Component/Screens/Preimumfeature/Preimumfeatures.js"));
const Iposcreen =  React.lazy(() => import("./Component/Screens/Iposcreen/Iposcreen.js"));

  const DashBoard =(Component)=>{
    return (
       <Layout>
        <Component />
        </Layout>
    
    )
  }

  const token = JSON.parse(localStorage.getItem("adminlogin"));
  const Secure = (Component) => {
    if (!token) {
      return <Navigate to="/" />;
    }

    return DashBoard(Component);

  }
  const InSecure = (Component) =>{
    if (token) {
      return <Navigate to="/dashboard" />;
    }
    return <Component />;
  };
  const routes = [
    { path: "/dashboard", element:Secure(Dashboard) },
    { path: "/users", element:Secure(Users) },
    { path: "/stocks", element:Secure(Stocks) },
    { path: "/stocks1", element:Secure(Stocks1) },
    { path: "/industry", element:Secure(Industry) },
    { path: "/sector", element:Secure(Sector) },
    { path: "/category", element:Secure(Category) },
    { path: "/categorystock", element:Secure(Categorystock) },
    { path: "/technical", element:Secure(Technical) },
    { path: "/stockscreener", element:Secure(StocksScreener) },
    { path: "/useradmin", element:Secure(Admin) },
    { path: "/fileupload", element:Secure(FileUpload) },
    { path: "/tasis", element:Secure(Tasis) },
    { path: "/", element:InSecure(Login) },
    { path: "/register", element: InSecure(Register)},
    { path: "/subscription", element: Secure(Subscription)},
    { path: "/preimumfeatures", element: Secure(Preimumfeatures)},
    { path: "/iposcreen", element: Secure(Iposcreen)},
    
    
    
  ];
  return (
      <Router>
        <Suspense fallback={"Loading"}>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
